import React from "react";
import {Box, Stack} from "@mui/material";
import {GridFooterContainer, GridPagination} from "@mui/x-data-grid";
import InCareNetHFService from "../../../services/InCareNetHFService";
import {InCareNetHFTableData} from "../../../models/InCareNetHF";
import {GridStateColDef} from "@mui/x-data-grid/internals";
import {sumArray} from "../../../utils/helperFunctions";

interface ICNTableFooterProps {
  columns: GridStateColDef[]
  tableData: InCareNetHFTableData[]
  dataGridRef: React.RefObject<HTMLDivElement | null>
}

export default function ICNTableFooter({ columns, tableData, dataGridRef }: ICNTableFooterProps) {
  const footerRef = React.useRef<HTMLDivElement>(null);

  /**
   * This useEffect handles scrolling of the data grid. It synchronizes the scroll of the actual data grid and the
   * footer.
   */
  React.useEffect(() => {
    const handleScroll = () => {
      if (footerRef.current && dataGridElement) {
        footerRef.current.scrollLeft = dataGridElement.scrollLeft;
      }
    };

    const dataGridElement = dataGridRef.current?.querySelector('.MuiDataGrid-virtualScroller');

    if (dataGridElement) {
      dataGridElement.addEventListener('scroll', handleScroll);
    }

    // Clean up the event listener on component unmount
    return () => {
      if (dataGridElement) {
        dataGridElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, [dataGridRef]);

  const aggregationColumns = InCareNetHFService.deviceCountColumns

  function createFooterColumns() {
    return columns.map((column, index: number) => {
      let columnWidth = column.width ? column.width : 0

      if (column.computedWidth === 0) {
        columnWidth = 0
      }

      if (index === 1) {
        // The second column should have a <p> with Total: as title for the footer
        return (
          <div
            key={index}
            style={{
              width: columnWidth,
              display: 'flex',
              textAlign: "left",
              alignItems: 'center',
              fontWeight: "bold",
            }}
          >
            <p style={{fontWeight: "bold", paddingLeft: "10px", margin: "0"}}>Total:</p>
          </div>
        );
      }

      return (
        <div
          key={index}
          style={{
            width: columnWidth,
            display: 'flex',
            textAlign: "left",
            alignItems: 'center',
            fontWeight: "bold",
          }}
        >
          {
            aggregationColumns.includes(column.field) ?
              (<p style={{fontWeight: "bold", paddingLeft: "10px", margin: "0"}}>{`${InCareNetHFService.aggregateDeviceCount(tableData, column)}`}</p>) :
              null
          }
        </div>
      );
    });
  }

  /**
   * Calculates the full width with overlay of the data grid with the columns.
   * This function is necessary for the correct alignment of the summary footer cells.
   */
  function getFullWidthOfFooter() {
    const widths = columns.map((column) => {
      return column.computedWidth
    })
    return sumArray(widths)
  }

  return (
    <GridFooterContainer
      style={{
        minHeight: "104px"
      }}
    >
      <Box
        sx={{
          pointerEvents: "none",
          height: "104px",
          overflowX: 'auto',
          '&::-webkit-scrollbar': {
            display: 'none' // For Chrome, Safari, and Opera
          }
        }}
        ref={footerRef}
      >
        <Stack spacing={0}>
          <Stack sx={{borderBottom: "2px solid rgb(224, 224, 224, .5)"}} direction={"row"} spacing={0} width={getFullWidthOfFooter()} height={"52px"}>
            {createFooterColumns()}
          </Stack>
          <Box sx={{width: "100%", height: "52px"}}>
            <GridPagination sx={{position: "absolute", right: "0", pointerEvents: "auto"}} />
          </Box>
        </Stack>
      </Box>
    </GridFooterContainer>
  );
}
