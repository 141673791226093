// @ts-ignore TS does not recognise keycloak-js for some reason
import Keycloak from 'keycloak-js';

const _kc = new Keycloak('/keycloak.json');
const env_stage = JSON.stringify(process.env.REACT_APP_ENV_STAGE);
const getUsername = () => _kc.tokenParsed?.preferred_username;
const hasRole = (roles: any) => roles.some((role: any) => _kc.hasRealmRole(role));
const doLogin = _kc.login;
const doLogout = _kc.logout;
const getToken = () => _kc.token;

const dev = env_stage === "\"development\"";
let isLoggedIn = dev ? () => true : () => !!_kc.token;

/**
 * Initializes KeycloakFunctions instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback: Function) => {
  _kc.onTokenExpired = () => {
    doLogout().then(() => {
      console.log("Token expired, logging out")
    })
  }

  _kc.init({
    onLoad: 'check-sso', silentCheckSsoFallback: false,

  })
    .then((authenticated: boolean) => {
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }
      onAuthenticatedCallback();
    })
    // eslint-disable-next-line no-console
    .catch(console.error);
};

/**
 * Updates the user's keycloak authentication token
 *
 * @param successCallback
 */
const updateToken = (successCallback: () => boolean) => {
  alert("updateToken")
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);
}

const KeycloakFunctions = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
};

export default KeycloakFunctions;
